.detail {
  height: 100%;
  overflow: hidden;
  position: relative;
  .detail_loading {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
  }
  .detail_conent {
    height: 100%;
    padding: 24px;
    overflow: auto;
  }
  // 基本信息
  .basic_info {
    height: 354px;
    background: #ffffff;
    border: 0.5px solid #f0f0f0;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .basic_info_h3 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #18191d;
      line-height: 26px;
      margin-bottom: 0px;
    }
    .basic_info_list {
      padding: 20px 20px 0px 20px;
      margin: 0px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 280px;
      .basic_info_item {
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        height: 54px;
        .basic_info_item_label {
          text-align: right;
          color: #999999;
          margin: 0px;
          user-select: none;
        }
        .basic_info_item_conent {
          text-align: left;
          color: #4d4d4d;
          line-height: 22px;
          margin: 0px 8px 0px 12px;
        }
        .basic_info_item_icon {
          font-size: 20px;
          color: #1790ff;
          cursor: pointer;
        }
      }
    }
    .basic_info_remark {
      margin: 0px 20px 0px 20px;
      display: flex;
      align-items: center;
      height: 54px;
      .basic_info_item_label {
        text-align: right;
        color: #999999;
        margin: 0px;
        user-select: none;
      }
      .basic_info_item_conent {
        text-align: left;
        color: #4d4d4d;
        line-height: 22px;
        margin: 0px 8px 0px 12px;
      }
    }
  }

  // 服务授权信息
  .serve_info {
    background: #ffffff;
    border: 0.5px solid #f0f0f0;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    .serve_info_option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .serve_info_h3 {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #18191d;
        line-height: 26px;
        margin-bottom: 0px;
      }
    }

    .serve_info_list {
      padding: 20px 20px 0px;
      .serve_info_list_h4 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #18191d;
        line-height: 24px;
        margin: 0px;
      }
      .serve_info_list_conent {
        list-style: none;
        padding: 20px 20px 0px;
        margin: 0px;
        .serve_info_list_conent_item {
          list-style: none;
          display: flex;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          line-height: 22px;
        }
        .serve_info_list_conent_item_edit {
          color: #606060;
          display: flex;
          // align-items: flex-start;
          align-items: center;
          .serve_info_list_conent_item_edit_icon {
            margin-left: 20px;
            font-size: 20px;
            color: #1790ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.p1 {
  color: #999999;
}
.p2 {
  color: #606060;
}
.p3 {
  color: #4d4d4d;
}
