.customerAdd {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        width: 900px;
        padding: 10px 20px;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 20px;
            margin-bottom: 16px;
        }
        .search {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            padding-left: 45px;
            .input {
                width: 300px;
                margin-right: 14px;
            }
            span {
                white-space: nowrap;
            }
        }
        .input {
            width: 280px;
        }
        .textarea {
            width: 710px;
        }
        .btnBox {
            width: 810px;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .btn {
                margin-left: 14px;
            }
        }
        :global {
            .ant-row, .ant-form-item {
                margin-bottom: 30px;
                label {
                    display: block;
                    width: 100px;
                    line-height: 32px;
                    text-align: right;
                }
            }
            .ant-form-inline .ant-form-item-with-help {
                margin-bottom: 0;
            }
        }
        .btnItem {
            margin-bottom: 0;
        }
        .hoverbox {
            display: flex;
            flex-direction: column;
            width: 280px;
        }
    }
}