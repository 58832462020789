.checkout_group_custom {
  display: flex;
}
.form_item_input {
  width: 320px;
}

.serve_check {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.serve_check_options {
  flex: 1;
  overflow: hidden;
  display: flex;
}

.serve_check_options_item {
  border: 1px solid #f0f0f0;
  padding: 24px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .ul_title {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: Regular;
    text-align: left;
    color: #18191d;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .check_ul {
    flex: 1;
    overflow: auto;
    padding: 0;
    list-style-type: none;
    .check_ul_li_check {
      margin-top: 10px;
      margin-bottom: 10px;
      .check_ul_li_check_options {
        > img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: Regular;
        text-align: left;
        color: #4d4d4d;
        line-height: 20px;
      }
      /** 子结构展示 */
      .check_li_options_ul {
        list-style-type: none;
        margin-top: 2px;
        margin-bottom: 8px;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 2px solid #f0f0f0;
        .check_li_options_ul_li_config {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: Regular;
          text-align: left;
          color: #b3b3b3;
          line-height: 20px;
        }
      }
    }
  }
}

.ul_li_tooltip_icon {
  font-size: 12px;
  color: #999999;
  cursor: pointer;
}

.out_service {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b3b3b3;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: Regular;
}

/**
 * 展示选项
 */
.serveexhibition {
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .serve_expirTime {
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: Regular;
    text-align: right;
    color: #999999;
    line-height: 22px;
  }
}
