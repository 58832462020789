.serve_add {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .step_div {
        display: flex;
        justify-content: center;
        .step {
            width: 500px;
        }
    }
    .step_conent {
        padding: 20px 20px 0px;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        .step_one {
           display: flex;
           justify-content: center;
           align-items: flex-start;
           .step_one_input {
            width: 446px;
           }
        }
        .step_two {
            display: flex;
            justify-content: center;
            .step_two_form {
                width: 800px;
            }
            .step_two_form_button {
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
            }
        }
        .step_three {
            height: 100%;
            width: 600px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .step_three_select {
                max-height: 100%;
                overflow: auto;
                padding: 0px 20px;
                margin: 20px 0px;
            }
            .step_three_form_button {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}