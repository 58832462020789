.invoices {
    padding-bottom: 10px;
    .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .button {
        transform: translateY(-6px);
        padding: 0 20px;
        margin-right: 10px;
    }
    .enterprise {
        .addInvoice {
            display: flex;
            align-items: center;
            p {
                margin-right: 14px;
            }
        }
        .operation {
            display: flex;
            width: 150px;
            justify-content: space-between;
            margin-top: 30px;
            margin-left: 186px;
        }
        .enterprise {
            margin-top: 50px;
            text-align: center;
            color: rgba(0, 0, 0, .45);
        }
    }
    .smTitle {
        font-size: 14px;
        margin-left: 40px;
        color: #000;
    }
    .line {
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-left: 20px;
        margin-bottom: 10px;
        .input {
            // width: 302px;
            margin-right: 20px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .input[disabled] {
            border-color: transparent;
            background-color: #fff;
            cursor: text;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 0;
        }
        .span {
            width: 156px;
            text-align: right;
            margin-right: 10px;
            color: #6b6b6b;
        }
    }
}